/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

const ImageRender = (
	imgSmall,
	imgMedium,
	imgLarge,
	defaultClass,
	defaultWidth,
	defaultHeight,
	alt,
	withParentWow = false,
	customSmall,
	customMedium,
	customLarge,
	withLazyLoad = false,
) => {
	const smSize = customSmall || 414;
	const mdSize = customMedium || 767;
	const lgSize = customLarge || 1690;

	if (withLazyLoad) {
		return (
			<picture>
				<source media={`(max-width: ${smSize}px)`} type="image/webp" data-srcset={imgSmall} />
				<source media={`(max-width: ${mdSize}px)`} type="image/webp" data-srcset={imgMedium} />
				<source media={`(max-width: ${lgSize}px)`} type="image/webp" data-srcset={imgLarge} />
				<img
					data-src={imgMedium}
					className={`lazyload ${defaultClass || ''}`}
					alt={alt}
					{...(defaultWidth && { width: defaultWidth })}
					{...(defaultHeight && { height: defaultHeight })}
				/>
			</picture>
		);
	}

	return (
		<picture>
			<source media={`(max-width: ${smSize}px)`} type="image/webp" srcSet={imgSmall} />
			<source media={`(max-width: ${mdSize}px)`} type="image/webp" srcSet={imgMedium} />
			<source media={`(max-width: ${lgSize}px)`} type="image/webp" srcSet={imgLarge} />
			<img
				srcSet={imgMedium}
				{...(defaultClass && { class: defaultClass })}
				alt={alt}
				{...(defaultWidth && { width: defaultWidth })}
				{...(defaultHeight && { height: defaultHeight })}
			/>
		</picture>
	);
};

export const SVGRender = (
	imgUrl,
	defaultClass,
	defaultWidth,
	defaultHeight,
	alt,
	withParentWow = false,
	withLazyLoad = false,
) => {
	if (withLazyLoad) {
		return (
			<img
				data-src={imgUrl}
				className={`lazyload ${defaultClass || ''}`}
				alt={alt}
				{...(defaultWidth && { width: defaultWidth })}
				{...(defaultHeight && { height: defaultHeight })}
			/>
		);
	}

	return (
		<img
			src={imgUrl}
			{...(defaultClass && { class: defaultClass })}
			alt={alt}
			{...(defaultWidth && { width: defaultWidth })}
			{...(defaultHeight && { height: defaultHeight })}
		/>
	);
};

export default ImageRender;
